<template>
  <div
    class="w-full bg-white rounded-3xl p-4 md:p-6 border border-newAsh flex flex-col gap-4"
  >
    <div class="flex items-center justify-between">
      <easiBackButton />
      <easiButton
        @click="openEdit"
        size="small"
        variant="outlined"
        class="gap-2"
      >
        Edit Item
        <img class="w-4" src="@/assets/icons/primary-edit.svg" alt="" />
      </easiButton>
    </div>

    <div class="w-full grid grid-cols-1 md:grid-cols-2 gap-4 md:gap-6">
      <div
        class="border-4 rounded-2xl overflow-hidden border-secondary h-96 flex items-center justify-center"
      >
        <img
          v-if="inventoryItem.image"
          :src="inventoryItem.image"
          class="h-full w-full object-cover"
          alt=""
        />
        <div v-else class="font-bold capitalize text-9xl">
          {{ initials(null, inventoryItem.name) }}
        </div>
      </div>

      <div class="flex flex-col gap-3 md:gap-6">
        <h2 class="text-lg md:text-2xl xl:text-3xl font-semibold capitalize">
          {{ inventoryItem.name }}
        </h2>
        <p class="text-sm md:text-base">
          {{ inventoryItem.description }}
        </p>

        <div class="flex items-center justify-between">
          <span class="text-lg font-semibold">
            {{ formatAmount(inventoryItem.sellingPrice) }}
          </span>
          <span class="text-secondary text-sm"> {{ unitsLeft }} left </span>
        </div>

        <div class="flex items-center gap-4 flex-wrap">
          <div
            v-for="item in items"
            :key="item"
            class="bg-newAsh border border-outlineGray px-3 py-2 rounded-full text-sm flex items-center gap-2"
          >
            <span>{{ item }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed, ref } from "vue";
import { useRoute, useRouter } from "vue-router";
import { helperFunctions } from "@/composable/helperFunctions";
import { useDataStore } from "@/stores/data.js";

const { formatAmount, checkNull,initials } = helperFunctions;

const store = useDataStore();
const route = useRoute();
const router = useRouter();

const inventoryId = computed(() => route.params.id);
const inventoryItem = computed(() => {
  return store.getInventoryItemById(inventoryId.value);
});

const unitsLeft = computed(() => {
  if (!inventoryItem.value) return 0;
  return inventoryItem.value.quantity - inventoryItem.value.unitsSold;
});

console.log("ITEM", inventoryItem.value);

const items = computed(() => {
  const arr = [];
  if (inventoryItem.value) {
    const category = store.getCategoryById(inventoryItem.value.category);
    if (category) {
      arr.push(category.name);
    }
    arr.push(inventoryItem.value.size);
    arr.push(inventoryItem.value.colour);
  }
  return arr;
});

const openEdit = () => {
  router.push({
    name: "ViewInventory",
    params: { id: inventoryId.value },
    query: { edit: "true" },
  });
};
</script>

<style></style>
