<template>
  <main class="px-2 md:px-4 py-6 flex flex-col gap-10 min-h-screen">
    <h4
      class="capitalize md:uppercase md:text-sm md:text-left text-xs font-medium text-headerText"
    >
      Book Keeping / Inventory / {{ inventoryId }} {{ isEdit && "/ Edit" }}
    </h4>

    <EditInventory v-if="isEdit" editMode :data="inventoryItem" />
    <ViewInventory v-else />
  </main>
</template>

<script setup>
import EditInventory from "@/components/BookKeeping/Inventory/AddEditInventory.vue";
import ViewInventory from "@/components/BookKeeping/Inventory/ViewInventory";

import { computed, ref } from "vue";
import { useRoute } from "vue-router";
import { helperFunctions } from "@/composable/helperFunctions";
import { useDataStore } from "@/stores/data.js";

const { formatAmount } = helperFunctions;

const store = useDataStore();
const route = useRoute();

const isEdit = computed(() => route.query.edit || null);
const inventoryId = computed(() => route.params.id || null);
const inventoryItem = computed(() => {
  return store.getInventoryItemById(inventoryId.value);
});
</script>

<style></style>
